.productos-pic {
  background: #373a47;
}
.box-otros {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: #373a47;
}

.logo-title {
  text-align: center;
  font-size: 10vw;
  font-family: Montserrat;
  width: 100%;
  color: #45cee3;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 50vh;
  transform: translateY(-50%);
}

.pic-container {
  width: 100%;
  padding-top: 100vh;
  div {
    display: inline-block;
    width: 33%;
    vertical-align: top;
  }
}
.pic-otros {
  width: 100%;
  padding: 10px;
}
.pic-box-otros {
  position: relative;
}
#otros-trigger {
}

.pic-box-otros-2 img {
  padding-top: 60px;
}
.modal-pic {
  width: 100%;
  object-fit: cover;
}
.modal-box-otros {
  background-color: #373a47;
  padding: 0;
}
.modal-otros .modal-content {
  background: none;
}
.otros-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.display-mobile-otros {
  display: none;
}
@media screen and (min-width: 280px) and (max-width: 900px) {
  .productos-pic {
    display: none;
  }
  .pic-otros {
    padding: 5px;
    width: 80%;
  }
  .display-mobile-otros {
    display: block;
    background: #373a47;
  }
  .mobile-title-otros {
    font-size: 30px;
    font-family: Montserrat;
    color: #45cee3;
    text-align: center;
  }
  .title-box-mobile {
    position: fixed;
    height: 100px;
    background: #373a47;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pic-mobile-box {
    padding-top: 25vh;
    text-align: center;
  }
  .send-text-1 {
    margin-top: 25px;
    width: 50px;
    height: 50px;
    font-size: 8px;
  }
  .send-btn-1 {
    padding-right: 15px;
  }
}
