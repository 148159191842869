.why-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b4ebf4;
}
.why-new-box {
  height: 700px;
  width: 1200px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background: mintcream;
}
.ul-box {
  padding-left: 100px;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
  width: calc((100% - 10px) / 2);
  vertical-align: middle;
  height: 100%;
  justify-content: center;
}
.li-container {
  display: flex;
  align-items: center;
  position: relative;
}
.li-box {
  list-style: none;
  font-family: Montserrat;
  font-size: 30px;
}
.li-box:hover {
  color: #45cee3;
}
.li-img {
  height: 50px;
  margin-right: 50px;
}
.square-box-active {
  border-left: 8px solid #45cee3;
  height: 70px;
  position: absolute;
  right: 0;
  opacity: 1;
}
square-box {
  opacity: 0;
}
.vertical-line {
  border-left: 3px solid #000;
  height: 63%;
  display: inline-block;
  width: 10px;
  vertical-align: middle;
}

.description-box {
  position: relative;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 10px) / 2);
  vertical-align: middle;
}
.d-b {
  position: absolute;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 1s;
  padding: 0 40px;
}
.d-b-active {
  position: relative;
  transform: translateY(0);
  opacity: 1;
}
.d-font {
  color: #45cee3;
  font-size: 50px;
  font-family: Montserrat;
}
.d-text {
  font-family: OpenSans;
}
.de-img {
  height: 120px;
}
.pointer-circle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
}

.why-container-1 {
  display: none;
}
.title-mobile-new {
  display: none;
}

@media screen and (min-width: 280px) and (max-width: 1000px) {
  .why-container {
    display: none;
  }
  .title-mobile-new {
    font-family: Montserrat;
    font-size: 32px;
    color: #45cee3;
    padding: 20px 0px;
    display: block;
    line-height: 1;
  }
  .why-container-1 {
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #b4ebf4;
    display: flex;
  }
  .why-new-box-1 {
    height: auto;
    width: 90%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background: mintcream;
    margin: 20px 0;
  }
  .mobile-why {
    text-align: center;
    margin: 30px;
  }
  .de-img {
    height: 50px;
  }
  .d-font {
    font-size: 30px;
  }
  .d-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1599px) {
  .why-new-box {
    margin: 50px 50px;
  }
  .ul-box {
    padding-left: 50px;
  }
}
