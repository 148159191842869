.video-box {
  height: 100vh;
  position: relative;
}
.video-new {
  width: 100vw;
  filter: grayscale(0.6);
  height: 100vh;
  object-fit: cover;
}
.video-img-1 {
  width: 100%;
  height: 100%;
  filter: invert(78%) sepia(12%) saturate(2039%) hue-rotate(142deg)
    brightness(91%) contrast(96%);
  object-fit: contain;
  object-position: center;
}
.video-img-1:hover {
  filter: invert(20%) sepia(59%) saturate(4869%) hue-rotate(299deg)
    brightness(116%) contrast(130%);
}
.arrow-new {
  bottom: 30px;
  position: absolute;
  z-index: 1;
  width: 100%;
  text-align: center;
}

.arrow-page {
  font-size: 70px;
  color: #45cee3;
  text-align: center;
}
.animation-font {
  position: relative;
}
.font-fx-1 {
  animation: fontDisplay;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 3s;
}
.font-fx {
  position: absolute;
  animation: moon;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  left: 0;
  top: 0;
  animation-delay: 3s;
  opacity: 0;
}
.font-fx-2 {
  animation: fontDisplay;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 5s;
}
.font-fx-a {
  position: absolute;
  animation: moon;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  left: 0;
  top: 0;
  animation-delay: 5s;
  opacity: 0;
}
.font-fx-3 {
  animation: fontDisplay;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 4s;
}
.font-fx-b {
  position: absolute;
  animation: moon;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  left: 0;
  top: 0;
  animation-delay: 4s;
  opacity: 0;
}
@keyframes fontDisplay {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes moon {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 400px) and (max-width: 1599px) {
  .arrow-page {
    font-size: 50px;
  }
  .arrow-new {
    bottom: 20px;
  }
}
