.header-new-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.header-menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
}
.header-new {
  margin-left: 100px;
}
.header-new-font {
  font-family: Montserrat, SansSerif;
  font-size: 35px;
  font-weight: bold;
  color: white;
}
.header-new-font:hover {
  color: #45cee3;
}
.logo-new {
  height: 150px;
}

[href] {
  color: black;
  text-decoration: none;
  font-size: inherit;
}
.whatsApp-const-2 {
  font-size: 75px;
  color: black;
}
.whatsApp-const-2:hover {
  color: white;
}
.hr-menu {
  display: block;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  border-style: inset;
  border-width: 1px;
  width: 400px;
  border-color: black;
}

/*Burger-Menu*/

.menu {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 25px;
}
.menu span {
  margin: 0 auto;
  position: relative;
  top: 12px;
}

.menu span:before,
.menu span:after {
  position: absolute;
  content: "";
}
.menu span,
.menu span:before,
.menu span:after {
  width: 30px;
  height: 6px;
  background-color: white;
  display: block;
}
.menu span:before {
  margin-top: -12px;
}
.menu span:after {
  margin-top: 12px;
}

/*Fake-Trigger*/
#sized {
  width: 60px;
  height: 50px;
  color: transparent;
  background-color: transparent;
  border: transparent;
}

/*Modal-1-Box*/
.modal-1 {
  display: none;
  position: fixed;
  z-index: 1;
  padding: 0.5%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-1-content-new {
  position: relative;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 98vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  background-color: #45cee3;
  border-radius: 30px;
}

.modal-1-header-new {
  padding: 2px 16px;
  position: absolute;
  right: 10px;
  z-index: 6;
}

.modal-1-body {
  padding: 2px 16px;
}

.modal-1-footer-new {
  padding: 2px 16px;
  color: black;
  border-top: none;
  text-align: center;
  height: 50%;
}

.modal-1-footer-new > p {
  color: black;
  font-size: 15px;
}

.close {
  color: black;
  font-size: 5em;
  font-weight: bold;
  opacity: 1;
  margin-right: 30px;
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.modal-1-body.nav {
  width: 100%;
  display: inline-block;
  height: 50%;
}
.box-cont {
  transform: translateY(-50%);
  position: relative;
  top: 60%;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*Navigation Inner Styling*/

.nav ul li a:hover {
  text-decoration: none;
  color: white;
}

.nav ul li a {
  padding: 1em;
  font-size: 2.5vw;
  letter-spacing: -0.05em;
  font-family: Montserrat;
}

.nav ul li {
  display: inline-block;
}

.nav ul {
  clear: both;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  position: relative;
  top: 70%;
}

///*Mobile Optimization*/
///* Portrait Tablet to Landscape and Desktop */
//@media (min-width: 280px) and (max-width: 979px) {
//  .nav ul li {
//    display: block;
//    height: 45px;
//  }
//
//  .nav ul {
//    clear: both;
//    width: 100%;
//    text-align: center;
//    padding: 0;
//  }
//
//  .nav ul li a {
//    padding: 0;
//    text-transform: uppercase;
//    font-weight: 700;
//    font-size: 6vw;
//    letter-spacing: -0.05em;
//  }
//}
//
///* Landscape-Phone to Portrait Tablet*/
//@media (max-width: 767px) {
//  .nav ul li {
//    display: block;
//    height: 38px;
//  }
//
//  .nav ul {
//    clear: both;
//    width: 100%;
//    text-align: center;
//    padding: 0;
//  }
//
//  .nav ul li a {
//    padding: 0;
//    text-transform: uppercase;
//    font-weight: 700;
//    font-size: 6vw;
//    letter-spacing: -0.05em;
//  }
//}
//
///*Landscape-Phone Downwards*/
//@media (max-width: 480px) {
//  .nav ul li {
//    display: block;
//    height: 38px;
//  }
//
//  .nav ul {
//    clear: both;
//    width: 100%;
//    text-align: center;
//    padding: 0;
//  }
//
//  .nav ul li a {
//    padding: 0;
//    text-transform: uppercase;
//    font-weight: 700;
//    font-size: 10vw;
//    letter-spacing: -0.05em;
//  }
//}
//
//@media screen and (min-width: 280px) and (max-width: 679px) {
//  .whatsApp-const-2 {
//    font-size: 50px;
//  }
//  .modal-1-footer-new hr {
//    margin-top: 15vh;
//  }
//  .modal-1-footer-new {
//    margin: auto;
//  }
//  .close {
//    font-size: 3em;
//  }
//  .hr-menu {
//    width: auto;
//  }
//  .modal-1-header-new {
//    height: 20vh;
//  }
//}
//
//@media screen and (min-width: 667px) and (max-height: 1000px) {
//  .whatsApp-const-2 svg {
//    font-size: 40px;
//  }
//  .modal-1-header-new {
//    height: 0vh;
//  }
//  .nav ul li a {
//    font-size: 4vw;
//  }
//  .modal-1-footer-new hr {
//    margin-top: 5vh;
//  }
//}
//@media screen and (min-width: 900px) and (max-height: 1080px){
//  .nav ul li a {
//    font-size: 3vw;
//  }
//  .modal-1-body{
//    margin-top: 200px;
//  }
//}
//@media screen and (min-width: 800px) and (max-height: 360px){
//  .nav ul li a{
//    font-size: 3vw;
//  }
//  .nav ul li{
//    height: 28px;
//  }
//  .modal-1-footer-new > p{
//    font-size: 12px;
//  }
//  .whatsApp-const-2 {
//    font-size: 10px;
//  }
//  .whatsApp-const-2 svg{
//    margin-bottom: 0;
//  }
//}
//@media screen and (min-width: 962px) and (max-height: 601px){
//  .modal-1-body{
//    margin-top: 100px;
//  }
//  .modal-1-footer-new{
//    margin-top: 50px;
//    padding: 0;
//    }
//
//}
//@media screen and (min-width: 963px) and (max-height: 810px){
//  .modal-1-footer-new{
//    margin-top: 150px;
//  }
//}
//@media screen and (min-height: 768px) and (max-width: 1280px){
//  .modal-1-footer-new hr{
//    margin-top: 20vh;
//  }
//}
@media screen and (min-width: 280px) and (max-width: 1000px) {
  .hr-menu {
    width: auto;
  }
  .box-cont {
    top: 60%;
  }
  .close {
    font-size: 50px;
    margin-right: 20px;
  }
  .whatsApp-const-2 {
    font-size: 50px;
  }
  .nav ul li a {
    font-size: 35px;
  }
  .nav ul li {
    display: block;
  }
  .nav ul {
    padding: 0;
  }
}
@media screen and (min-height: 375px) and (max-width: 1024px) {
  .nav ul li a {
    font-size: 30px;
  }
  .whatsApp-const-2 {
    font-size: 40px;
  }
  .box-cont {
    top: 50%;
  }
  .nav ul {
    top: 50%;
  }
}
