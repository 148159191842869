.productos-pic {
  background: #373a47;
}
.box-jacket {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: #373a47;
}

.logo-title {
  text-align: center;
  font-size: 10vw;
  font-family: Montserrat;
  width: 100%;
  color: #45cee3;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 50vh;
  transform: translateY(-50%);
}

.pic-container {
  width: 100%;
  padding-top: 100vh;
  div {
    display: inline-block;
    width: 33%;
    vertical-align: top;
  }
}
.pic-jacket {
  width: 100%;
  padding: 10px;
}
.pic-box-jacket {
  position: relative;
}

.pic-box-jacket-2 img {
  padding-top: 60px;
}
.modal-pic {
  width: 100%;
  object-fit: cover;
}
.modal-box-jacket {
  background-color: #373a47;
  padding: 0;
}
.modal-jacket .modal-content {
  background: none;
}
.jacket-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.back-button {
  color: white;
}
.send-btn-1 {
  text-align: end;
  padding-right: 50px;
}
.send-text-1 {
  font-family: Montserrat;
  width: 70px;
  height: 70px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #45cee3;
  margin-top: 50px;
  border: none;
  box-shadow: none;
  outline: none;
  color: white;
  font-size: 11px;
}
.display-mobile-jacket {
  display: none;
}
@media screen and (min-width: 280px) and (max-width: 900px) {
  .productos-pic {
    display: none;
  }
  .pic-jacket {
    padding: 5px;
    width: 80%;
  }
  .display-mobile-jacket {
    display: block;
    background: #373a47;
  }
  .mobile-title-jacket {
    font-size: 30px;
    font-family: Montserrat;
    color: #45cee3;
    text-align: center;
  }
  .title-box-mobile {
    position: fixed;
    height: 100px;
    background: #373a47;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pic-mobile-box {
    padding-top: 10vh;
    text-align: center;
  }
  .send-text-1 {
    margin-top: 25px;
    width: 50px;
    height: 50px;
    font-size: 8px;
  }
  .send-btn-1 {
    padding-right: 15px;
  }
}
