.home-new-container {
  height: 100vh;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
}
.home-new-font {
  font-family: Montserrat;
  font-size: 10vw;
  text-align: start;
  line-height: 10vw;
  margin-left: 8vw;
  color: #45cee3;
}
.home-new-font:hover {
  color: white;
}

@media screen and (min-width: 280px) and (max-width: 1599px) {
}
