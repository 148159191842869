

@font-face {
  font-family: Apercu;
  src: local('Apercu'), url('../fonts/Apercu/Apercu Bold.otf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: FjallaOne;
  src: local('FjallaOne'), url('../fonts/Fjalla_One/FjallaOne-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: Montserrat;
  src: local('Montserrat'), url('../fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: Nexa;
  src: local('Nexa'), url('../fonts/nexa/Nexa Regular.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: Nexa;
  src: local('Frutiger'), url('../fonts/Frutiger-Font/FTLC.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family:OpenSans;
  src: local('OpenSans'), url('../fonts/Open_Sans/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family:OpenSans;
  src: local('OpenSans-Medium'), url('../fonts/Open_Sans/OpenSans/OpenSans-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family:OpenSans;
  src: local('OpenSans-Regular'), url('../fonts/Open_Sans/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family:OpenSans;
  src: local('OpenSans-Light'), url('../fonts/Open_Sans/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
}


