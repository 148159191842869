.footer-home {
  height: 19vh;
  background: #373a47;
  color: white;
  position: relative;
  text-align: center;
}
.footer-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.whatsApp-const-1 {
  font-size: 70px;
  color: #01e676;
}

.whatsApp-const-1:hover {
  color: white;
}
@media screen and (min-width: 280px) and (max-width: 1800px) {
  .whatsApp-const-1 {
    font-size: 50px;
  }
  .footer-box {
    font-size: 13px;
  }
  .footer-home {
    height: 200px;
  }
}
