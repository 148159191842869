.color-box {
  background-color: #373a47;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.color-text {
  position: relative;
  mask-image: url("/assets/logo.svg");
  mask-size: contain;
  width: 70vw;
  height: 70vh;
  mask-repeat: no-repeat;
  mask-position: center;
}
.color-1 {
  background-color: #373a47;
  width: 100%;
  height: 100%;
}
.color-2 {
  background-color: #45cee3;
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
}

#logo {
  position: absolute;
  top: -100%;
  left: -100%;
}
