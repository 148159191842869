.contact-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.pic-contact {
}
.second-text {
  font-family: OpenSans;
  font-size: 25px;
  margin-top: 20px;
}
.form-contact {
  width: 1270px;
  height: 70vh;
  background-color: #45cee3;
  border-radius: 15px;
  text-align: center;
}
.contact-text {
  font-family: Montserrat;
  font-size: 2vw;
  margin-top: 40px;
  line-height: 1;
}
.input-text {
  text-align: left;
  height: 8vh;
  width: 40vw;
  border: none;
  background-color: #45cee3;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-radius: 0;
  font-family: OpenSans;
  font-size: 18px;
}
.input-text:focus {
  outline: none;
}
.input-text::placeholder {
  margin-left: 30px;
  color: black;
}

.text-area-contact {
  width: 40vw;
  height: 12vh;
  text-align: left;
  background-color: #45cee3;
  border: none;
  margin-top: 60px;
  font-family: OpenSans;
  font-size: 18px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.text-area-contact::placeholder {
  color: black;
}
.send-text {
  color: black;
  font-family: Montserrat;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #45cee3;
  margin-top: 50px;
  border-color: black;
  border-bottom-style: solid;
  border-width: thin;
  box-shadow: none;
  outline: none;
  text-align: center;
}
.send-btn {
  margin-bottom: 50px;
  text-align: center;
}
.field-text {
  color: white;
}
.modal-form {
  background: #45cee3;
  border-bottom: none;
}
.modal-form-1 {
  background: #45cee3;
}
.modal-footer {
  border-top: none;
}
.modal-title {
  font-family: Montserrat;
}

@media screen and (min-width: 280px) and (max-width: 1587px) {
  .contact-box {
    height: auto;
  }
  .form-contact {
    width: 90vw;
    margin-top: 40px;
    margin-bottom: 40px;
    height: auto;
  }
  .text-area-contact {
    width: 80vw;
    font-size: 17px;
  }
  .input-text {
    width: 80vw;
    font-size: 17px;
  }
  .contact-text {
    font-size: 20px;
    margin: 20px 20px;
  }
  .second-text {
    font-size: 17px;
  }
  .send-text {
    width: 100px;
    height: 100px;
    font-size: 12px;
  }
}
@media screen and (min-width: 1280px) and (max-height: 1080px) {
  .form-contact {
    height: auto;
  }
}
