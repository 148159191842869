.menu-box {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  font-family: Montserrat;
  font-size: 30px;
  align-items: center;
  color: black;
  background-color: white;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 0;
}

.li-nav a:hover {
  color: #45cee3;
}
@media screen and (min-width: 280px) and (max-width: 1200px) {
  .menu-box {
    display: none;
  }
}
