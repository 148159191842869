.construction-box{
  opacity: 0.9;
  height: 100vh;
  position: relative;
}
.text-const{
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.font-const{
  color: #45cee3;
  font-size: 4vw;
  font-family: FjallaOne, sans-serif;
  top: 40%;
  text-align: center;
}
.font-const-1{
  color: #45cee3;
  font-size: 2vw;
  font-family: FjallaOne, sans-serif;
  top: 40%;
  text-align: center;
}
.back-const{
    width: 100%;
    height: auto;
}

.whatsApp-const{
  font-size: 4vw;
  color: #01e676;
}
.whatsApp-const :hover{
  color: white;
}
@media screen and (min-width: 200px) and (max-width: 1382px){
  .back-const{
    height: 100%;
    object-fit: cover;
  }

}
@media screen and (min-width: 200px) and (max-width: 860px){
  .font-const{
    font-size: 35px;
  }
  .font-const-1{
    font-size: 25px;
  }
  .whatsApp-const{
    font-size: 50px;
  }
}
