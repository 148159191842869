.parallax-box {
  padding: 400px;
}

.pic-one {
  height: 33vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/assets/2.jpg");
}
.pic-two {
  height: 33vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/assets/7.jpg");
}
.pic-three {
  height: 33vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/assets/1.webp");
}
