/*body {*/
/*    font-family: "Quicksand", sans-serif;*/
/*    margin: 0;*/
/*    text-align: center;*/
/*}*/

/*.headerPagos {*/
/*    background-color: #afe4eb;*/
/*    width: 100%;*/
/*    margin: auto;*/
/*    padding: 50px;*/
/*    text-align: center;*/
/*    box-sizing: border-box;*/
/*}*/

/*h1 {*/
/*    font-weight: 400;*/
/*    font-size: 40px;*/
/*    color: black;*/
/*}*/

/*.infoHeader {*/
/*    font-size: 22px;*/
/*    color: black;*/
/*}*/

/*.formPagos {*/
/*    margin: 40px auto;*/
/*    padding: 50px;*/
/*    width: 600px;*/

/*    border-color: rgb(79 189 224);*/
/*    border-width: 2px;*/
/*    border-style: solid;*/
/*    border-radius: 12px;*/
/*}*/

/*.filePagos {*/
/*    padding: 20px;*/
/*}*/

/*input,*/
/*select {*/
/*    width: 100%;*/
/*    height: 30px;*/
/*    border-radius: 10px;*/
/*    text-align: center;*/
/*}*/

/*input:focus {*/
/*    box-shadow: 0 0 0 4px rgb(79 189 224 / 50%);*/
/*    outline: 0;*/
/*}*/

/*label {*/
/*    width: 100px;*/
/*    display: inline-block;*/
/*    font-size: 20px;*/
/*}*/

/*.salto {*/
/*    margin-top: 40px;*/
/*}*/

/*.pedido {*/
/*    font-size: 30px;*/
/*}*/

/*.orderPedido select {*/
/*    width: 200px;*/
/*}*/

/*.send {*/
/*    width: 100px;*/
/*    height: 50px;*/
/*    border-radius: 12px;*/
/*    font-size: 15px;*/
/*    background-color: white;*/
/*    border-style: solid;*/
/*}*/

/*.send:hover {*/
/*    background-color: rgb(79, 189, 224);*/
/*    color: white;*/
/*    border-style: none;*/
/*}*/

/*.fa-plus {*/
/*    font-size: 15px;*/
/*}*/

/*.plusButton {*/
/*border-width: thin;*/
/*border-style: solid;*/
/*border-radius: 10px;*/
/*border-color: black;*/
/*width: 200px;*/
/*margin: 15px auto;*/
/*cursor: pointer;*/

/*}*/

/*.minusButon {*/
/*    height: 30px;*/
/*    width: 40px;*/
/*}*/

/*.cantidad {*/
/*    margin-left: 20px;*/

/*}*/

/*!* mensaje solicitud envio*!*/

/*.solicitudEnviada {*/
/*    font-size: 40px;*/
/*    padding: 150px;*/
/*    width: 600px;*/
/*    margin: 0 auto 100px;*/
/*    background-color: rgb(79 189 224);*/
/*    color: white;*/
/*    display: none;*/
/*}*/

/*.fa-check-square {*/
/*    font-size: 150px;*/
/*    margin-bottom: 30px;*/
/*}*/

/*@media only screen*/
/*and (min-width: 320px)*/
/*and (max-width: 1380px) {*/
/*    .headerPagos {*/
/*        background-color: #afe4eb;*/
/*        width: 100%;*/
/*        margin: auto;*/
/*        padding: 20px;*/
/*        text-align: center;*/
/*        box-sizing: border-box;*/
/*    }*/

/*    .pedidos-title {*/
/*        font-size: 22px;*/
/*    }*/

/*    .infoHeader {*/
/*        font-size: 12px;*/
/*        color: black;*/
/*    }*/

/*    .formPagos {*/
/*        margin: 20px auto;*/
/*        padding: 25px;*/
/*        width: 350px;*/
/*color: white;*/
/*        border-color: rgb(79 189 224);*/
/*        border-width: 2px;*/
/*        border-style: solid;*/
/*        border-radius: 12px;*/
/*    }*/

/*    input,*/
/*    select {*/
/*        width: 100%;*/
/*        height: 30px;*/
/*        border-radius: 10px;*/
/*        text-align: center;*/
/*        font-size: 15px;*/
/*    }*/

/*    .pedido {*/
/*        font-size: 25px;*/
/*        text-align: center;*/
/*    }*/

/*    #contenedorPedido {*/
/*        background-color: white;*/
/*    }*/
/*    .fondo-page{*/
/*        background-color: #3a3a3a;*/
/*    }*/

/*}*/
