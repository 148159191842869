.containerPedido{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.producto{
    width: 400px;
}
.cantidad{
    width: 160px;
}
.trash{
    font-size: 15px;
    margin-left: 15px;
}

