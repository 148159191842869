.pic-gallery{
    display: block;
    min-height: 1px;
    overflow: auto;
}

#lightboxBackdrop svg{
    fill: black;
}

button.close_1x3s325 svg{
    fill: white !important;
}
.producto-pic{
    height: 500px;
    object-fit: cover;
    width: 100%;
}
.pic-carrousel{
    object-fit: cover;
}
.image-gallery-svg{
    color: black;
  }
@media only screen
and (min-width: 320px)
and (max-width: 1380px){
    .producto-pic{
        height: 230px;
        object-fit: cover;
        width: 100%;
    }
}