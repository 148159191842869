.home-container{
    /*max-width: 1600px;*/
    margin: auto;
    background-color: white;
   /* margin-top: 80px;*/
    position: relative;
    left: -100%;
}
@media only screen
and (min-width: 320px)
and (max-width: 1380px) {

    .home-container{
        background-color: #3a3a3a;
        margin-top: 0px;
    }
}
