.we-new-container {
  height: 100vh;
  position: relative;
}
.we-new {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #45cee3;
  border-radius: 15px;

  opacity: 0.8;
}

.we-div-box {
  padding: 80px;
  position: absolute;
  top: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.title-box {
  width: 50%;
}
.we-new-font {
  font-family: OpenSans, sans-serif;
  color: black;
  font-size: 1vw;
  text-align: start;
  line-height: 1.7vw;
  padding: 0px 330px 30px 50px;
}
.text-box {
  width: 50%;
}
.we-new-title {
  font-family: Montserrat;
  font-size: 4vw;
  color: white;
  font-weight: bolder;
  text-align: right;
  padding: 0 50px 0 300px;
}

.we-new-title:hover {
  color: black;
}

@media screen and (min-width: 280px) and (max-width: 1599px) {
  .we-div-box {
    padding: 40px;
    height: auto;
    position: relative;
  }
  .title-box {
    width: 100%;
    margin-top: 50px;
  }
  .we-new {
    display: inline-block;
  }
  .we-new-title {
    font-size: 30px;
    text-align: center;
    padding: 0;
  }
  .we-new-font {
    padding: 0;
    line-height: 1.4;
    font-size: 16px;
  }
  .text-box {
    width: 100%;
    padding: 20px;
  }
  .we-new-container {
    height: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1750px) {
  .we-new-font {
    font-size: 22px;
    padding: 0;
    margin: 0 30px;
  }
  .we-div-box {
    position: relative;
  }
}
@media screen and (min-width: 1751px) and (max-width: 2250px) {
  .we-new-font {
    font-size: 22px;
  }
}
