.products-title {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  color: #45cee3;
  font-size: 140px;
  margin-top: 100px;
  width: 100%;
  text-align: center;
  text-shadow: 1px 1px 2px #484848;
}
.producto-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
}

.products-main {
  height: 100vh;
  display: flex;
  position: relative;
}
.pic-products {
  width: calc(100% / 3);
  height: 100%;
  //background-repeat: no-repeat;
  //background-position: center;
  font-size: 50px;
  font-family: Montserrat;
  color: #45cee3;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 300px;
}
.font-products {
  cursor: pointer;
  text-shadow: 1px 1px 2px #484848;
}
.font-products:hover {
  color: black;
  text-shadow: none;
}
@media screen and (min-width: 280px) and (max-width: 1000px) {
  .products-title {
    font-size: 50px;
    line-height: 1;
    text-align: center;
    padding-bottom: 100px;
    margin-top: 50px;
  }
  .pic-products {
    font-size: 35px;
    padding-bottom: 200px;
  }
  .font-products {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
  }
}
@media screen and (min-width: 500px) and (max-height: 600px) {
  .font-products {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    z-index: 3;
  }
  .pic-products {
    padding-bottom: 130px;
    font-size: 25px;
  }
}
@media screen and (min-width: 1024px) and (max-height: 1080px) {
  .products-title {
    line-height: 1;
    font-size: 100px;
  }
}

@media screen and (min-height: 360px) and (max-height: 450px) {
  .nav ul li a {
    font-size: 25px;
    line-height: 0.8;
  }
  .box-cont {
    top: 40%;
  }
  .nav ul {
    top: 52%;
  }
  .hr-menu {
    width: 50%;
  }
}
